<template>
  <div class="container mx-auto mb-8">
    <h2 class="text-mid">{{ item.title }}</h2>
    <div class='issuu-embed-container container mx-auto' v-if="issue">
      <iframe allowfullscreen allow="fullscreen" tabindex="0" :title="item.title"
              :src="'//e.issuu.com/embed.html?' + issue">
      </iframe>
    </div>
  </div>
</template>

<script>
import * as URL from 'url'
import {Blok} from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'Issuu',
  extends: Blok,
  computed: {
    issue () {
      if (this.item.url && this.item.url.length > 0) {
        let url = URL.parse(this.item.url)
        let path = url.pathname.split('/')

        if (path.length === 4) {
          return URL.URLSearchParams({
            d: path[3],
            u: path[1],
            hideIssuuLogo: this.item.hide_logo ? 'true' : 'false',
            hideShareButton: this.item.hide_share_button ? 'true' : 'false',
            pageNumber: this.item.page_number
          }).toString()
        }
      }

      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.issuu-embed-container {
  position: relative;
  padding-bottom: 56.25%; /* set the aspect ratio here as (height / width) * 100% */
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
